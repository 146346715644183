import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { DashboardDbService } from '../_services';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'], 
  providers: []
})
export class DashboardPageComponent implements OnInit, AfterViewInit {
  @Input() totalQTY: number;

  readonly ROOT_URL = 'https://jsonplaceholder.typicode.com'
  
    SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS: any;

    url: string = 'https://jsonplaceholder.typicode.com/users/';
    usersArray = [];
    posts: any;

    qtyLoggedIn: number;

    private ngUnsubscribe = new Subject();

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private dashboardService: DashboardDbService
    ) {
      this.loadQTY();
    } 
      
    getPosts() {
      this.posts = this.http.get(this.ROOT_URL + '/posts')
    }

    loadQTY() {
      this.dashboardService.getQTY()
        .pipe(
          startWith({qty:this.dashboardService.qtyLoggedIn}),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          data => {
              this.qtyLoggedIn = data.qty;
          }
        );
    }


    /*
    showInfo() {
      this.toastr.info('Hello world!', 'Toastr fun!', {
        
      });
    }

    showWarning() {
      this.toastr.warning('Hello world!', 'Toastr fun!', {
        
      });
    }

    showSuccess() {
      this.toastr.success('Hello world!', 'Toastr fun!', {
        
      });
    }

    showError() {
      this.toastr.error('Hello world!', 'Toastr fun!', {
        
      });
    }*/

  ngOnInit() {
    $("#alert-target").click(function () {
      this.toastr["info"]("I was launched via jQuery!")
    });
  }

  showSuccess(data: any) {
    this.toastr.success('User: ' + '"' + data.name + '"' + ' successfully fetched');
  }

  showError(id: number) {
    this.toastr.error("Error: User with ID: " + id + " doesn't exists");
  }

  connectionEnd(id: number) {
    this.toastr.error('Connection ended after ' + id + ' calls');
  }

  getData(id: number) {
    return this.http.get(this.url + id);
  }

  ngAfterViewInit() {
    /*let i = 1;
    const getDataInterval = setInterval(() => {
      this.getData(i).subscribe((response: any) => {
        //console.log(response);
        //response = response.json();
        this.usersArray.push(response.name);
        this.showSuccess(response);
        i++;
      }, error => {
        if (i < 15) {
          this.showError(i);
        } else {
          clearInterval(getDataInterval);
          this.connectionEnd(i);
        }
        i++;
      });
    }, 2000);*/
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
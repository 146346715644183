import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { TestpageComponent } from './testpage/testpage.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PopupComponent } from './popup/popup.component';
import { LoginComponent } from './login';
import { AuthGuard } from './_guards';

const routes: Routes = [
  {
    path: '',
    component: DashboardPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'testpage',
    component: TestpageComponent
  },
  {
    path: 'popup',
    component: PopupComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
      routes,
      {
        enableTracing: false // <-- debugging purposes only
      }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
//import { Mongoose } from 'mongoose';

@Injectable({
  providedIn: 'root'
})
export class DashboardDbService {

  qtyLoggedIn: number;

  constructor( private socket: Socket ) {}

  getQTY() {
    return Observable.create(observer => {
      this.socket.on('broadcast', data => {
        observer.next(data);
        if(data.qty > 0)
          this.qtyLoggedIn = data.qty;
        else
          this.qtyLoggedIn = 0;
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';
import { User } from '../_models';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  currentUser: User;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(map(obs => {
    return obs;
  }));

  constructor( 
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private _auth: AuthenticationService
  ) { 
    this._auth.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    //$('mnuTemp').click(function(){
      //console.log($(this).parent().html());
    //});
    //console.log('isHandset',this.isHandset);
  }

  toggleCollapse() {
    $('i.rotate-icon').toggleClass('rotated');
  }

  logout(){
    this._auth.logoutUser();
    this.router.navigate(['/login']);
  }
}

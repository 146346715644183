import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Exports } from '../_models';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class ExportsService {
  //private serviceUrl = 'http://localhost:3000/exports';
  private serviceUrl = '//nodejs.tyrenet.nl/exports';

  currentExport = this.socket.fromEvent<Exports>('updateExport');
  refreshExport = this.socket.fromEvent<Exports>('refreshExports');

  constructor(private http: HttpClient, private socket:Socket) {
    
  }

  getExports(
    sortOrder = 'asc'
  ): Observable<Exports[]> {
    //console.log('url=',this.http.get<Exports[]>(this.serviceUrl));
    //console.log('----------------------------------------------------------------------------------------------');
    return this.http.get<Exports[]>(this.serviceUrl, {
      params: new HttpParams()
        .set('sort', 'title')
        .set('sortOrder', sortOrder)
    });
  }

  refreshExports(){
    console.log('[TEMP]');
    return Observable.create(observer => {
        this.socket.on('refreshExports', data => {
          console.log('data=',data);
          observer.next(data);
        });
    })
  }

  updateExports() {
    console.log('[TEMP2]');
  }
}

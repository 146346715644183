import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private router: Router,
    private _auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // RESET LOGIN STATUS
    this._auth.logoutUser();

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.loading = true;
    this.submitted = true;

    this._auth.loginUser(this.loginForm.value)
      .subscribe(
        res => {
          if(this.loginForm.invalid) {
            return;
          }
          this.router.navigate([this.returnUrl]);
        },
        err => {
          this.error = err.error;
          this.loading = false;
          this.submitted = false;
          this.loginForm.reset();
        }
    );
  }

}

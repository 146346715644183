import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavbarModule, WavesModule, ButtonsModule, CollapseModule } from 'angular-bootstrap-md';

/*import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';*/

import { MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatProgressSpinnerModule,
  MatTableModule, MatExpansionModule, MatSelectModule, MatSnackBarModule, MatTooltipModule, MatChipsModule, MatListModule, MatSidenavModule, MatTabsModule, MatProgressBarModule, 
  MatSortModule
} from '@angular/material';

import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { RouterModule, Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestpageComponent } from './testpage/testpage.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ExportsComponent } from './widgets/exports/exports.component';

import { ExportsService } from './_services';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentComponent } from './document/document.component';
import { TestComponent } from './widgets/test/test/test.component';
import { PopupComponent } from './popup/popup.component';
import { LoginComponent } from './login/login.component';

//const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
const config: SocketIoConfig = { url: '//nodejs.tyrenet.nl', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    TestpageComponent,
    PageNotFoundComponent,
    DashboardPageComponent,
    SidenavComponent,
    ExportsComponent,
    DocumentListComponent,
    DocumentComponent,
    TestComponent,
    PopupComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    NavbarModule, WavesModule, ButtonsModule, CollapseModule,

    MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatProgressSpinnerModule,
    MatTableModule, MatExpansionModule, MatSelectModule, MatSnackBarModule, MatTooltipModule, MatChipsModule, MatListModule, MatSidenavModule, MatTabsModule, MatProgressBarModule,
    MatSortModule, 

    AppRoutingModule,
    HttpClientModule, 
    ToastrModule.forRoot(),
    SocketIoModule.forRoot(config)
  ],
  providers: [ExportsService],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(router: Router) {
    //const replacer = (key, value) => (typeof value === 'function') ? value.name : value;
    //console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
    
  }
}

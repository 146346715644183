import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
//import { ExportsService } from '../_services/exports.service';
import { Exports } from '../_models/exports.model';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  //public message: string;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Exports/*,
    public exportService: ExportsService*/
  ) { 
  }

  ngOnInit() {
    //this.message = this.data.toString();
    //this.message = this.data;
  }

}
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ExportsService } from '../../_services/exports.service';
import { Observable, Subscription } from 'rxjs';
import { Exports } from 'src/app/_models/exports.model';
import { MatSort, MatDialog, MatTableDataSource, MatTable } from '@angular/material';
import { PopupComponent } from '../../popup/popup.component';
import { ToastrService } from 'ngx-toastr';
import { DataSource } from '@angular/cdk/table';
import { map } from 'rxjs/operators';

@Component({
  selector: 'widget-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent implements OnInit, OnDestroy {
  private _exportName: Subscription;

  public dataSource:any;

  displayedColumns = ['title', 'last_run', 'next_run', 'status'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(
      private exportsService: ExportsService, 
      public dialog: MatDialog,
      private toastr: ToastrService
  ) {
    this.exportsService.getExports().subscribe(tblData => {
      this.dataSource = new MatTableDataSource<Exports>(tblData);
      this.dataSource.sort = this.sort;
    });

    this.exportsService.refreshExports().subscribe(data => {
      //console.log('REFRESH');
      this.exportsService.getExports().subscribe(tblData => {
        this.dataSource = new MatTableDataSource<Exports>(tblData);
        this.dataSource.sort = this.sort;
      });
    });
  }
  
  ngOnInit() {
    this._exportName = this.exportsService.currentExport
    .pipe(
      //startWith({ id: 0, doc: 'test'})
      // MAP HIERONDER IS IN PRINCIPE NIET NODIG!
      map(res => {
        //console.log('res',res);
        
        return {
            name: res.name,
            last_run: res.last_run,
            next_run: res.next_run,
            status: res.status,
            title: res.title,
            error_message: res.error_message
        };
      })
    )
    .subscribe(
        exports => {
          this.dataSource.data.forEach(item => {

            //console.log('Item: ', JSON.stringify(item));

            if(item.name === exports.name) {
              item.last_run = exports.last_run;
              item.next_run = exports.next_run;
              item.status = exports.status;
              item.title = exports.title;
              item.error_message = exports.error_message;
            }
          });

          // SORT TABLE
            this.dataSource._updateChangeSubscription();

          // TOASTR BY ERROR 
            if(exports.status == 3){
              this.toastr.error('Fout met ' + exports.title,'Error', {
                'progressBar': true,
              });
              this.table.renderRows();
            }
        }
    )
  }



  rowClick(index:string) {
    //console.log('Row ' + index + ' is clicked');
  }

  showError(msg:Exports) {
    this.dialog.open(PopupComponent,{
      data: {content: msg.error_message}
    });
  }

  ngOnDestroy() {
    this.table.renderRows();
    this._exportName.unsubscribe();
  }

}

export class ExportsDataSource extends DataSource<any> {
  constructor(
      private exportsService: ExportsService
  ) {
    super();
  }
  connect(): Observable<Exports[]> {
    return this.exportsService.getExports();
  }

  disconnect() {}
}
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './_services';
import { User } from './_models';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Tyrenet Dashboard';

  currentUser: User;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(map(obs => {
    console.log('obs',obs);
    return obs;
  }));

  public isCollapsed = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private socket: Socket,
    private router: Router,
    private _auth: AuthenticationService
  ) { 
    socket.on('broadcast', function(data){
      console.log('APP-component.TS:',data);
    });
    this._auth.currentUser.subscribe(x => this.currentUser = x);
  }

  public ngOnInit() {
    $(document).ready(function() {
      //console.log('jQuery allowed');
      
      /* SIDENAV MENU */
        $('app-sidenav a').each(function(){
          $(this).removeClass('active');
          $('app-sidenav a[href="/"]').addClass('active');
        });

        $('app-sidenav a').click(function() {
          $('app-sidenav a').each(function(){
            $(this).removeClass('active');
          });
          $(this).addClass('active');
        });
    });
  }

  logout(){
    this._auth.logoutUser();
    this.router.navigate(['/login']);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //private _loginUrl = 'http://localhost:3000/login';
  private _loginUrl = '//nodejs.tyrenet.nl/login';
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) { 
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  loginUser(user) {
    console.log('USER = ', user);
    //return this.http.get(this._loginUrl, user);
    //return this.http.post<any>(this._loginUrl, user);
    return this.http.post<User[]>(this._loginUrl, user)
      .pipe(map(userPipe => {
        if(user) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return userPipe;
      }));
    ;
  }

  logoutUser() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null)
  }
}
